import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./components/ui/table";

interface DOBsProps {
  searchTerm: string;
  currentPage: number;
  updateTotalPages: (total: number) => void;
}

interface DOB {
  town_full_name: string;
  address_line_1: string;
  address_line_2: string;
  zip_code: string;
  state: string;
  phone_number: string;
  fax_number: string;
  construction_official: string;
  building_subcode_official: string;
  electrical_subcode_official: string;
  plumbing_subcode_official: string;
  fire_subcode_official: string;
  elevator_subcode_official: string;
}

const DOBs: React.FC<DOBsProps> = ({ searchTerm, currentPage, updateTotalPages }) => {
  const [dobs, setDobs] = useState<DOB[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDOBs = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://architects.buildgpt.xyz/api/dobs?page=${currentPage}&pageSize=20&search=${searchTerm}`);
        const data = await response.json();
        setDobs(data.dobs);
        updateTotalPages(Math.ceil(data.total / 20));
        setLoading(false);
      } catch (err) {
        console.error('Error fetching DOBs:', err);
        setError('Failed to fetch Department of Buildings data');
        setLoading(false);
      }
    };

    fetchDOBs();
  }, [searchTerm, currentPage, updateTotalPages]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Town</TableHead>
          <TableHead>Address</TableHead>
          <TableHead>Zip</TableHead>
          <TableHead>State</TableHead>
          <TableHead>Phone Number</TableHead>
          <TableHead>Fax Number</TableHead>
          <TableHead>Officials</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {dobs.map((dob, index) => (
          <TableRow key={index}>
            <TableCell>{dob.town_full_name}</TableCell>
            <TableCell>{`${dob.address_line_1} ${dob.address_line_2}`}</TableCell>
            <TableCell>{dob.zip_code}</TableCell>
            <TableCell>{dob.state}</TableCell>
            <TableCell>{dob.phone_number}</TableCell>
            <TableCell>{dob.fax_number}</TableCell>
            <TableCell>
              <div>Construction: {dob.construction_official}</div>
              <div>Building: {dob.building_subcode_official}</div>
              <div>Electrical: {dob.electrical_subcode_official}</div>
              <div>Plumbing: {dob.plumbing_subcode_official}</div>
              <div>Fire: {dob.fire_subcode_official}</div>
              <div>Elevator: {dob.elevator_subcode_official}</div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default DOBs;