import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./components/ui/table";

interface ArchitectsProps {
  searchTerm: string;
  currentPage: number;
  updateTotalPages: (total: number) => void;
}

interface Contact {
  id: number;
  contact_first: string;
  contact_last: string;
  title: string;
  direct_phone: string;
  email: string;
}

interface Company {
  id: number;
  company_name: string;
  city: string;
  state: string;
  phone: string;
  website: string;
  about_company: string;
  contacts: Contact[];
}

const Architects: React.FC<ArchitectsProps> = ({ searchTerm, currentPage, updateTotalPages }) => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchArchitects = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://architects.buildgpt.xyz/api/architects?page=${currentPage}&pageSize=20&search=${searchTerm}`);
        const data = await response.json();
        setCompanies(data.companies);
        updateTotalPages(Math.ceil(data.total / 20));
        setLoading(false);
      } catch (err) {
        console.error('Error fetching architects:', err);
        setError('Failed to fetch architects');
        setLoading(false);
      }
    };

    fetchArchitects();
  }, [searchTerm, currentPage, updateTotalPages]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Company Name</TableHead>
          <TableHead>City</TableHead>
          <TableHead>State</TableHead>
          <TableHead>Phone</TableHead>
          <TableHead>Website</TableHead>
          <TableHead>About Company</TableHead>
          <TableHead>Contacts</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {companies.map((company) => (
          <TableRow key={company.id}>
            <TableCell>{company.company_name}</TableCell>
            <TableCell>{company.city}</TableCell>
            <TableCell>{company.state}</TableCell>
            <TableCell>{company.phone}</TableCell>
            <TableCell>
              <a href={company.website} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                {company.website}
              </a>
            </TableCell>
            <TableCell>{company.about_company}</TableCell>
            <TableCell>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Name</TableHead>
                    <TableHead>Title</TableHead>
                    <TableHead>Direct Phone</TableHead>
                    <TableHead>Email</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {company.contacts.map((contact) => (
                    <TableRow key={contact.id}>
                      <TableCell>{`${contact.contact_first} ${contact.contact_last}`}</TableCell>
                      <TableCell>{contact.title}</TableCell>
                      <TableCell>{contact.direct_phone}</TableCell>
                      <TableCell>{contact.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default Architects;