import React, { useState, useCallback } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "./components/ui/card";
import { Input } from "./components/ui/input";
import { Button } from "./components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./components/ui/select";
import { Search } from "lucide-react";
import Architects from './Architects';
import DOBs from './DOBs';
import DobDenials from './DobDenials';

type DataSource = 'architects' | 'dobs' | 'dob_denials';

const App: React.FC = () => {
  const [dataSource, setDataSource] = useState<DataSource>('architects');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handleDataSourceChange = (value: DataSource) => {
    setDataSource(value);
    setCurrentPage(1);
    setSearchTerm('');
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCurrentPage(1);
    // Trigger search in child component
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const updateTotalPages = useCallback((total: number) => {
    setTotalPages(total);
  }, []);

  return (
    <Card className="w-full max-w-7xl mx-auto">
      <CardHeader>
        <CardTitle className="text-2xl font-bold text-center mb-4">BuildGPT Data Management Interface</CardTitle>
        <div className="flex space-x-4">
          <Select onValueChange={handleDataSourceChange} value={dataSource}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select data source" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="architects">Architects</SelectItem>
              <SelectItem value="dobs">Department of Buildings</SelectItem>
              <SelectItem value="dob_denials">DOB Denials</SelectItem>
            </SelectContent>
          </Select>
          <form onSubmit={handleSearch} className="flex-1 flex">
            <div className="relative flex-1">
              <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <Input
                type="text"
                placeholder={`Search ${dataSource}...`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 w-full"
              />
            </div>
            <Button type="submit" className="ml-2">
              Search
            </Button>
          </form>
        </div>
      </CardHeader>
      <CardContent>
        {dataSource === 'architects' ? (
          <Architects 
            searchTerm={searchTerm} 
            currentPage={currentPage} 
            updateTotalPages={updateTotalPages}
          />
        ) : dataSource === 'dobs' ? (
          <DOBs 
            searchTerm={searchTerm} 
            currentPage={currentPage} 
            updateTotalPages={updateTotalPages}
          />
        ) : (
          <DobDenials 
            searchTerm={searchTerm} 
            currentPage={currentPage} 
            updateTotalPages={updateTotalPages}
          />
        )}
        <div className="flex justify-center mt-4 space-x-4">
          <Button 
            onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="self-center">Page {currentPage} of {totalPages}</span>
          <Button 
            onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default App;