import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./components/ui/table";

interface DobDenialsProps {
  searchTerm: string;
  currentPage: number;
  updateTotalPages: (total: number) => void;
}

interface DobDenial {
  id: number;
  state: string;
  town: string;
  work_address: string;
  contractor: string;
  work_description: string;
  denial_items: string;
  document_url: string;
}

interface DenialSummary {
  Statute: string;
  "Full Description": string;
  Occurrences: number;
}

const Modal: React.FC<{ isOpen: boolean; onClose: () => void; children: React.ReactNode }> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-4xl max-h-[80vh] overflow-y-auto">
        <button onClick={onClose} className="float-right text-xl font-bold">&times;</button>
        {children}
      </div>
    </div>
  );
};

const DenialsSummaryModal: React.FC<{ town: string; isOpen: boolean; onClose: () => void }> = ({ town, isOpen, onClose }) => {
  const [summaryData, setSummaryData] = useState<DenialSummary[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      const fetchSummaryData = async () => {
        setLoading(true);
        try {
          const response = await fetch(`https://architects.buildgpt.xyz/api/denials_summary/${encodeURIComponent(town)}`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setSummaryData(data);
        } catch (err) {
          console.error('Error fetching denials summary:', err);
          setError(`Failed to fetch denials summary: ${err instanceof Error ? err.message : 'Unknown error'}`);
        } finally {
          setLoading(false);
        }
      };

      fetchSummaryData();
    }
  }, [isOpen, town]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="text-xl font-bold mb-4">Denials Summary for {town}</h2>
      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {!loading && !error && (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Statute</TableHead>
              <TableHead>Full Description</TableHead>
              <TableHead>Occurrences</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {summaryData.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.Statute}</TableCell>
                <TableCell>{item["Full Description"]}</TableCell>
                <TableCell>{item.Occurrences}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Modal>
  );
};

const DobDenials: React.FC<DobDenialsProps> = ({ searchTerm, currentPage, updateTotalPages }) => {
  const [denials, setDenials] = useState<DobDenial[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTown, setSelectedTown] = useState('');

  useEffect(() => {
    const fetchDobDenials = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://architects.buildgpt.xyz/api/dob_denials?page=${currentPage}&pageSize=20&search=${searchTerm}`);
        const data = await response.json();
        setDenials(data.denials);
        updateTotalPages(Math.ceil(data.total / 20));
        setLoading(false);
      } catch (err) {
        console.error('Error fetching DOB denials:', err);
        setError('Failed to fetch DOB denials data');
        setLoading(false);
      }
    };

    fetchDobDenials();
  }, [searchTerm, currentPage, updateTotalPages]);

  const openModal = (town: string) => {
    setSelectedTown(town);
    setModalOpen(true);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>ID</TableHead>
            <TableHead>State</TableHead>
            <TableHead>Town</TableHead>
            <TableHead>Work Address</TableHead>
            <TableHead>Contractor</TableHead>
            <TableHead>Work Description</TableHead>
            <TableHead>Denial Items</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {denials.map((denial) => (
            <TableRow key={denial.id}>
              <TableCell>{denial.id}</TableCell>
              <TableCell>{denial.state}</TableCell>
              <TableCell>
                <span 
                  className="cursor-pointer text-blue-500 hover:underline"
                  onClick={() => openModal(denial.town)}
                >
                  {denial.town}
                </span>
              </TableCell>
              <TableCell>
                <a href={denial.document_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                  {denial.work_address}
                </a>
              </TableCell>
              <TableCell>{denial.contractor}</TableCell>
              <TableCell>{denial.work_description}</TableCell>
              <TableCell>{denial.denial_items}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DenialsSummaryModal
        town={selectedTown}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default DobDenials;